import React, { useState, useEffect } from "react";
import { useParams, useLocation } from 'react-router-dom';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import {
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import { Card, Row, Col } from "antd";
import * as config from "../common/config";
import Firebase from "../lib/Firebase";
import ServiceMap from "../components/ServiceMap";
import Loading from "../components/Loading";
import Input from "../components/Input";
import NotFound from './NotFound';

dayjs.extend(utc);
dayjs.extend(tz);

const MapComponent = withScriptjs(withGoogleMap(ServiceMap));
const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${config.GOOGLE_API_KEY}&callback=Function.prototype`;

const getBookingStatus = (status) => {
    switch(status) {
        case 1:
        case 2:
            return "Assigned to a vehicle and driver"
        case 3:
            return "Completed, tracking ends";
        case 4:
        case 7:
        case 8:
            return "Cancelled";
        case 5:
            return "No show";
        case 6:
            return "Passenger onboard";
        default:
            return "";
    }
}

const RiderWebVehicleTracking = (props) => {
    const {setLogo} = props;
    const [trip, setTrip] = useState(null);
    const [fleetId, setFleetId] = useState(null);
    const [fleet, setFleet] = useState(null);
    const {tripId} = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const bid = queryParams.get('bid');
    const timezone = trip?.program?.timezone || "utc";
    const bookings = Object.keys(trip?.bookings || []).map((id) => ({id, ...trip.bookings[id]}));
    const firstBooking = bid ? bookings.find((obj) => obj.id === bid) : bookings[0];
    const bookingStatus = firstBooking?.ride_status;
    const scheduledPickupTime = dayjs(firstBooking?.scheduled_pickup_time &&
        firstBooking.scheduled_pickup_time.toDate()).tz(timezone);
    const isDriverOnroute = !firstBooking?.driver_arrival_timestamp && firstBooking?.driver_enroute_time;
    const pickupTime = firstBooking?.pickup_time;
    const isDriverArrived = (firstBooking?.driver_arrival_timestamp && !firstBooking?.driver_enroute_time)
        || (firstBooking?.driver_arrival_timestamp && firstBooking?.driver_enroute_time);
    const tripDate = pickupTime ? dayjs(pickupTime).tz(timezone).format("MMMM DD, YYYY") : "";
    const bookingStatusLabel = getBookingStatus(bookingStatus);
    const [tripData, tripLoading, tripError] = Firebase.getTripData(tripId);

    // starts tracking conditions:
    // if trip date is in the current day
    // or if 30 mins before scheduled pickup time
    // or if ride status is 6
    // or if driver is on route
    // or if driver is arrived
    let hideMap = dayjs(scheduledPickupTime).diff(dayjs().tz(timezone), "m") > 30
        || dayjs(tripDate).isBefore(dayjs().tz(timezone).format("MMMM DD, YYYY"), "day")
        || tripError
        || !tripData
        || false;

    useEffect(() => {
        document.title = 'Duet: Rider Web Vehicle Tracking';
    }, []);

    useEffect(() => {
      // initialize trip data
      setTrip(tripData);
    }, [tripData]);

    useEffect(() => {
        if (trip && !fleetId) {
            setFleetId(trip?.fleet?.id);
        }
    }, [trip, fleetId]);

    useEffect(() => {
        (async () => {
            if (fleetId) {
                const fleetData = await Firebase.getFleetDataById(fleetId);

                if (fleetData) {
                    setLogo(fleetData?.logo);
                    setFleet(fleetData);
                }
            }
        })();
    }, [fleetId, setLogo]);

    if (isDriverOnroute || isDriverArrived) {
        hideMap = false;
    }

    switch(bookingStatus) {
        case 6:
            hideMap = false;
            break;
        case 3:
        case 4:
        case 5:
        case 7:
        case 8:
            hideMap = true;
            break;
        default:
            // tbd
    }

    if (!tripLoading && (tripError || !tripData || (trip && !firstBooking))) {
		return <NotFound />;
	}

    return (
        <>
            { !tripLoading && fleet ?
                <div style={{textAlign: "left"}}>
                    <span className="fleet-name">
                        {fleet?.name}
                    </span>
                    <Card size="small" className="description-container">
                        <Row gutter={[24, 8]}>
                            <Col flex={1}>
                                <Input label="Trip date" value={tripDate}/>
                            </Col>
                            <Col flex={1}>
                                <Input label="Rider" value={firstBooking?.passenger_name || ""}/>
                            </Col>
                            <Col flex={1}>
                                <Input label="Status" value={bookingStatusLabel} inputStyle={{fontWeight: 600}}/>
                            </Col>
                        </Row>
                        <span style={{color: "#9398A0", marginTop: 20, display: "block"}}>
                            Tracking starts 30 minutes before the scheduled pickup time,
                            and ends once the trip is completed or the trip date ends.
                        </span>
                    </Card>
                    <br/>
                    { hideMap ?
                        <div className="trip-not-available">
                            <span className="trip-not-available-text">
                                { tripError ?
                                    "Invalid link. Refresh your browser tab. If you still don't see the tracking map, then please contact your transport provider."
                                    : "Tracking is not available because the trip either hasn't started or was completed already."
                                }
                            </span>
                        </div>
                        : <MapComponent
                            {...props}
                            booking={firstBooking}
                            trip={trip}
                            googleMapURL={mapURL}
                            loadingElement={<div style={{ height: "100%" }} />}
                            containerElement={<div className="map-container"/>}
                            mapElement={<div style={{ height: "100%" }} />}
                        />
                    }
                </div>
                : <Loading />
            }
        </>
    );
};

export default RiderWebVehicleTracking;
